import {
    Box, Button,
    Flex,
    FormControl, FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement, Select, SelectField,
    Stack, Switch,
    Textarea
} from "@chakra-ui/react";
import {useFormik} from "formik";
import {validationSchema} from "./addOrderValidationSchema";
import {useEffect, useState} from "react";
import {createOrder, getProjects} from "./orderService";
import {toast, ToastContainer} from "react-toastify";
import { useNavigate } from "react-router-dom"
import { defaultCertificateText } from "./orderService";

const AddOrder = () => {

    const [projects, setProject] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {

        (async () => {
            const { items }  = await getProjects()
            setProject(items)
        })();

    }, [])

    const {addOrder, handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            certificateText: defaultCertificateText,
            quantity: '',
            totalPrice: '',
            currencyCode: 'GBP',
            projectId: '',
            isInvoice: false,
            taxNumber: ''
        },
        mode: 'onBlur',
        validationSchema,
        onSubmit: async values => {
            setLoading(true)
            try {
                const result = await createOrder(values)
                setLoading(false)
                toast.success(`Sipariş Eklendi ${result.orderId}`)
                setTimeout(() => {
                    navigate('/app/orders')
                }, 3000)
            } catch (e) {
                setLoading(true)
            }

        }
    })

    return (
        <>
            <Stack>
                <Box mt={4}>
                    <form onSubmit={handleSubmit}>
                        <Flex>
                            <FormControl mr={2} isInvalid={!!errors.firstName} isRequired>
                                <FormLabel htmlFor={"firstName"}>
                                    Firstname
                                </FormLabel>
                                <Input
                                    id={"firstName"}
                                    name={"firstName"}
                                    onChange={handleChange}
                                    value={values.firstName}
                                    ref={addOrder}
                                    placeholder={"Firstname"} />
                                <FormErrorMessage>{errors?.firstName}</FormErrorMessage>
                            </FormControl>
                            <FormControl mr={2} isInvalid={!!errors.lastName} isRequired>
                                <FormLabel htmlFor={"lastName"}>
                                    Lastname
                                </FormLabel>
                                <Input
                                    id={"lastName"}
                                    name={"lastName"}
                                    onChange={handleChange}
                                    value={values.lastName}
                                    placeholder={"Lastname"} />
                                <FormErrorMessage>{errors?.lastName}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.email} isRequired>
                                <FormLabel htmlFor={"email"}>
                                    Email
                                </FormLabel>
                                <Input
                                    id={"email"}
                                    type={"email"}
                                    name={"email"}
                                    onChange={handleChange}
                                    value={values.email}
                                    placeholder={"Email "} />
                                <FormErrorMessage>{errors?.email}</FormErrorMessage>
                            </FormControl>
                        </Flex>
                        <FormControl mt={2} isInvalid={!!errors.companyName} isRequired>
                            <FormLabel htmlFor={"companyName"}>
                                Company Name
                            </FormLabel>
                            <Input
                                id={"companyName"}
                                name={"companyName"}
                                onChange={handleChange}
                                value={values.companyName}
                                placeholder={"Company Name"} />
                            <FormErrorMessage>{errors?.companyName}</FormErrorMessage>
                        </FormControl>
                        <FormControl mt={2} isInvalid={!!errors.certificateText} isRequired>
                            <FormLabel htmlFor={"certificateText"}>
                                Certificate Text
                            </FormLabel>
                            <Textarea
                                id={"certificateText"}
                                name={"certificateText"}
                                onChange={handleChange}
                                value={values.certificateText}
                                placeholder={"Sertifikada bulunacak text"} />
                            <FormErrorMessage>{errors?.certificateText}</FormErrorMessage>
                        </FormControl>
                        <Flex mt={2}>
                            <FormControl mr={2} isInvalid={!!errors.quantity} isRequired>
                                <FormLabel htmlFor={"quantity"}>
                                    Quantity
                                </FormLabel>
                                <Input
                                    type={"number"}
                                    id={"quantity"}
                                    name={"quantity"}
                                    onChange={handleChange}
                                    value={values.quantity}
                                    ref={addOrder}
                                    placeholder={"Quantity"} />
                                <FormHelperText>Kg olarak giriniz</FormHelperText>
                                <FormErrorMessage>{errors?.quantity}</FormErrorMessage>
                            </FormControl>
                            <FormControl mr={2} isInvalid={!!errors.totalPrice} isRequired>
                                <FormLabel htmlFor={"totalPrice"}>
                                    Total Price
                                    <InputGroup mt={2}>
                                        <Input
                                            id={"totalPrice"}
                                            type={"number"}
                                            name={"totalPrice"}
                                            onChange={handleChange}
                                            value={values.totalPrice}
                                            ref={addOrder}
                                            placeholder={"Total Price"}
                                        />
                                        <InputRightElement>
                                            <SelectField
                                                mr={6}
                                                name={"currencyCode"}
                                                defaultValue={values.currencyCode}
                                                onChange={handleChange}
                                            >
                                                <option value={"GBP"}>GBP</option>
                                                <option value={"USD"}>USD</option>
                                                <option value={"TRY"}>TRY</option>
                                                <option value={"EUR"}>EUR</option>
                                            </SelectField>
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage>{errors?.totalPrice}</FormErrorMessage>
                                </FormLabel>
                            </FormControl>
                            <FormControl mr={2} isInvalid={!!errors.projectId} isRequired>
                                <FormLabel htmlFor={"projectId"}>
                                    Project
                                </FormLabel>
                                <Select
                                    id={"projectId"}
                                    name={"projectId"}
                                    onChange={handleChange}
                                    ref={addOrder}
                                    placeholder={"Proje Seçiniz"}
                                >
                                    {
                                        projects.map((project) => {
                                            return (
                                                <option
                                                    key={project.id}
                                                    value={project.id}
                                                >{project.name} -- {project.countryId.name}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormErrorMessage>{errors?.projectId}</FormErrorMessage>
                            </FormControl>
                        </Flex>
                        <Flex mt={2}>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor='isInvoice' mb='0'>
                                    Co2nsensus Fatura Kesiyor mu?
                                </FormLabel>
                                <Switch
                                    id='isInvoice'
                                    name={"isInvoice"}
                                    onChange={handleChange}
                                    ref={addOrder}
                                    size={"lg"}
                                />
                            </FormControl>
                            {
                                values.isInvoice &&
                                <FormControl mr={2} >
                                    <FormLabel htmlFor={"taxNumber"}>
                                        Vergi Dairesi / Vergi No
                                    </FormLabel>
                                    <Input
                                        id={"taxNumber"}
                                        name={"taxNumber"}
                                        onChange={handleChange}
                                        value={values.taxNumber}
                                        placeholder={"Vergi Dairesi / Vergi No"} />
                                </FormControl>
                            }
                        </Flex>
                        <Button
                            mt={"4rem"}
                            borderRadius={0}
                            type="submit"
                            variant="solid"
                            colorScheme="teal"
                            isLoading={loading}
                            loadingText={'Submitting...'}
                        >
                            Kaydet
                        </Button>
                    </form>
                </Box>
            </Stack>
            <ToastContainer theme={'colored'} />
        </>
    )
}

export default AddOrder